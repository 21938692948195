@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Zayne";
  src: url('../src/fonts/Zayne.otf');
}

@font-face {
  font-family: "Nunitosands" ;
  src: url('https: //fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300&display=swap')
}

@font-face {
  font-family: "Century";
  src: url('../src/fonts/Century.ttf');
}

@font-face {
  font-family: 'Inter', sans-serif;
  src: url('https: //fonts.googleapis.com/css2?family=Inter:wght@100;200;300;700&display=swap')
}

@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300&display=swap');

@layer base {
  body {
    @apply font-body text-paragraph leading-6 bg-black;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply text-white font-primary;
  }
  .section {
    @apply py-28 lg:py-32;
  }
  .section-title {
    @apply text-3xl lg:text-4xl font-medium lg:font-extrabold mb-5;
  }
  .subtitle {
    @apply text-base text-paragraph mb-16 lg:mb-24 max-w-[520px];
  }
  .btn {
    @apply py-4 px-7 font-medium text-white flex items-center justify-center rounded-sm hover:bg-accent-hover transition-all;
  }
  .btn-lg {
    @apply h-[54px];
  }
  .btn-md {
    @apply h-[48px];
  }
  .input {
    @apply bg-secondary text-paragraph h-[60px] outline-none pl-6 w-full font-body text-[15px] rounded-sm focus:outline focus:outline-1 focus:outline-accent;
  }
  .textarea {
    @apply bg-secondary resize-none w-full outline-none p-6 rounded-sm h-[200px] focus:outline focus:outline-1 focus:outline-accent;
  }
  .active {
    @apply text-accent;
  }
  .blue__gradient {
    background: linear-gradient(90deg, rgba(212, 124, 7, 0) 0%, #a54005 130%);
    filter: blur(123px);
  }

    @import url('https://cdn.jsdelivr.net/npm/lightgallery@2.4.0/css/lightgallery.css');
    @import url('https://cdn.jsdelivr.net/npm/lightgallery@2.4.0/css/lg-zoom.css');
    @import url('https://cdn.jsdelivr.net/npm/lightgallery@2.4.0/css/lg-video.css');
    
    @import "~react-image-gallery/styles/css/image-gallery.css";
    @import url("leaflet/dist/leaflet.css");
 
}
